// Basic layout styles
@import "dependencies/helpers.scss";
@import "./variables.scss";

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  width: 100%;
}
body {
  line-height: 1.25;
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
}

.icon-component {
  align-items: center;
  display: flex;
  justify-content: center;
}
