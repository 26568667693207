@import './../../../styles/variables.scss';

.container {
  width: 300px;
  background: #161a34;
  min-height: 100vh;
  .title {
    font-weight: bold;
    font-size: 24px;
    width: inherit;
    color: #ffffff;
    padding: 20px 16px 0 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  .icon {
    margin-right: 8px;
    font-size: 24px;
  }

  .list {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .item2 {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
    width: 215px;
    color: #ffffff;
    height: 54px;
    cursor: pointer;
    margin: auto;
    span {
      margin-left: 12px;
      margin-right: 12px;
      padding-left: 12px;
      padding-top: 6px;
      padding-bottom: 6px;
      width: 100%;
      border: solid 1px rgba(255, 255, 255, 0);
      border-radius: 8px;
      display: flex;
      align-items: center;
      font-size:18px;
    }
    span:hover {
      color: #25a28c;
    }
    .selected {
      color: #25a28c;
    }
  }

  .item {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 24px;
    width: 215px;
    color: #ffffff;
    height: 54px;
    cursor: pointer;
    // border: solid 1px red;
    margin: auto;
    span {
      padding-top: 6px;
      padding-bottom: 6px;
      border: solid 1px rgba(255, 255, 255, 0);
      border-radius: 8px;
      display: flex;
      align-items: center;

      p {
        margin: 0;
      }
    }
    span:hover {
      color: #25a28c;
    }
    p{
      font-size:18px;
    }
    .rotate {
      transform: rotate(90deg);
    }

    .selected {
      color: #25a28c;
    }

    .option {
      ul {
        padding-inline-start: 0 !important;
      }
      span {
        padding-left: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
        width: 100%;
        border: solid 1px rgba(255, 255, 255, 0);
        display: flex;
        align-items: center;
      }
    }
    .qrCode{
      margin-right: 5px;
    }
  }

  .menu {
    font-weight: bold;
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 40px;
  }

  .logout {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    width: 190px;
    padding-left: 60px;
    color: #ffffff;
    height: 54px;
    cursor: pointer;
    margin: auto;
  }
}
