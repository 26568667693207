@import '../../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  min-width: 100vw;
  align-items: center;
  justify-content: center;
  background: $primary-22;
  .logo {
    color: #ffffff;
    span {
      padding: 10px 20px;
      border-radius: 18px 0 18px 0;
      font-size: 24px;
      font-weight: bold;
      color: white;
      border: double rgba(0, 0, 0, 0) 2px;
      background: linear-gradient(45deg, #0db683 14.64%, #0d84ac 85.36%);
    }
  }
  .header {
    font-size: 24px;
    font-weight: bold;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
    color: $primary-10;
    span {
      margin-left: 40px;
    }
  }
}
