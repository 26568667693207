.content {
    display: flex;
    flex-direction: row;
}
.wrapper {
  background: rgb(255, 255, 255);
  display: flex !important;
  width: auto;
  justify-content: center;
  margin: auto;
}
